import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  Button,
  Badge,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import { FaShoppingCart } from "react-icons/fa";
import { Menu as MenuIcon, Close as CloseIcon } from "@mui/icons-material";
import Logo from "../images/logo.png";

import "./Navbar.css";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <>
      <AppBar
        position="sticky"
        className="appbar"
        color="default"
        style={{ borderRadius: "71px", zIndex: "99" }}
      >
        <Toolbar>
          {/* Logo */}
          <div style={{ flexGrow: 1 }}>
            <Link to="/">
              <img src={Logo} alt="Logo" style={{ height: "80px" }} />
            </Link>
          </div>
          <div className="web-title">
            <Link to="/" sx={{ textDecoration: "none" }}>
              <h1>SkiCom - Play To Win</h1>
            </Link>
          </div>

          {/* Hamburger Menu for Mobile */}
          <div className="mobile-menu">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                <IconButton onClick={toggleDrawer(false)}>
                  <CloseIcon />
                </IconButton>
                <List>
                  <ListItem
                    button
                    component="a"
                    href="https://www.skicomltd.com"
                  >
                    <ListItemText primary="Skicom Home" />
                  </ListItem>
                  <ListItem
                    button
                    component="a"
                    href="https://www.skicomltd.shop"
                  >
                    <ListItemText primary="Skicom Shop" />
                  </ListItem>
                  <ListItem button component="a" href="/me/profile">
                    <ListItemText primary="Profile" />
                  </ListItem>
                </List>
              </Box>
            </Drawer>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;
