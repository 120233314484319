import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const API_URL = "https://api-play.skicomltd.com/api/admin";

export default function AdminPanel() {
  const [prizes, setPrizes] = useState([]);
  const [token, setToken] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [currentPrize, setCurrentPrize] = useState({
    option: "",
    style: { backgroundColor: "", textColor: "" },
    probability: 0,
  });

  useEffect(() => {
    const storedToken = localStorage.getItem("adminToken");
    if (storedToken) {
      setToken(storedToken);
      fetchPrizes(storedToken);
    }
  }, []);

  const login = async () => {
    try {
      const response = await axios.post(`${API_URL}/login`, {
        username,
        password,
      });
      setToken(response.data.token);
      localStorage.setItem("adminToken", response.data.token);
      fetchPrizes(response.data.token);
    } catch (error) {
      console.error("Login failed:", error);
      alert("Login failed. Please check your credentials.");
    }
  };

  const fetchPrizes = async (authToken) => {
    try {
      const response = await axios.get(`${API_URL}/prizes`, {
        headers: { "x-auth-token": authToken },
      });
      setPrizes(response.data);
    } catch (error) {
      console.error("Error fetching prizes:", error);
    }
  };

  const handleOpenDialog = (
    prize = {
      option: "",
      style: { backgroundColor: "", textColor: "" },
      probability: 0,
    }
  ) => {
    setCurrentPrize(prize);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSavePrize = async () => {
    try {
      if (currentPrize._id) {
        await axios.put(`${API_URL}/prizes/${currentPrize._id}`, currentPrize, {
          headers: { "x-auth-token": token },
        });
      } else {
        await axios.post(`${API_URL}/prizes`, currentPrize, {
          headers: { "x-auth-token": token },
        });
      }
      fetchPrizes(token);
      handleCloseDialog();
    } catch (error) {
      console.error("Error saving prize:", error);
      alert("Error saving prize. Please try again.");
    }
  };

  const handleDeletePrize = async (id) => {
    if (window.confirm("Are you sure you want to delete this prize?")) {
      try {
        await axios.delete(`${API_URL}/prizes/${id}`, {
          headers: { "x-auth-token": token },
        });
        fetchPrizes(token);
      } catch (error) {
        console.error("Error deleting prize:", error);
        alert("Error deleting prize. Please try again.");
      }
    }
  };

  if (!token) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <Paper className="p-8">
          <h2 className="text-2xl font-bold mb-4">Admin Login</h2>
          <TextField
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={login}
            fullWidth
            className="mt-4"
          >
            Login
          </Button>
        </Paper>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Admin Panel</h1>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenDialog()}
        className="mb-4"
      >
        Add New Prize
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Option</TableCell>
              <TableCell>Background Color</TableCell>
              <TableCell>Text Color</TableCell>
              <TableCell>Probability</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prizes.map((prize) => (
              <TableRow key={prize._id}>
                <TableCell>{prize.option}</TableCell>
                <TableCell>{prize.style.backgroundColor}</TableCell>
                <TableCell>{prize.style.textColor}</TableCell>
                <TableCell>{prize.probability}</TableCell>
                <TableCell>
                  <Button onClick={() => handleOpenDialog(prize)}>Edit</Button>
                  <Button onClick={() => handleDeletePrize(prize._id)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {currentPrize._id ? "Edit Prize" : "Add New Prize"}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Option"
            value={currentPrize.option}
            onChange={(e) =>
              setCurrentPrize({ ...currentPrize, option: e.target.value })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label="Background Color"
            value={currentPrize.style.backgroundColor}
            onChange={(e) =>
              setCurrentPrize({
                ...currentPrize,
                style: {
                  ...currentPrize.style,
                  backgroundColor: e.target.value,
                },
              })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label="Text Color"
            value={currentPrize.style.textColor}
            onChange={(e) =>
              setCurrentPrize({
                ...currentPrize,
                style: { ...currentPrize.style, textColor: e.target.value },
              })
            }
            fullWidth
            margin="normal"
          />
          <TextField
            label="Probability"
            type="number"
            value={currentPrize.probability}
            onChange={(e) =>
              setCurrentPrize({
                ...currentPrize,
                probability: parseFloat(e.target.value),
              })
            }
            fullWidth
            margin="normal"
            inputProps={{ step: 0.05, min: 0, max: 1 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleSavePrize} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
