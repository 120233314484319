import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
  Alert,
  AlertTitle,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";

const SPIN_DURATION = 5000; // 5 seconds
const SPIN_PRICE = 100; // 100 Naira
const PAYSTACK_PUBLIC_KEY = "pk_test_e2d60fc87604a1b56304caddf32593714abba788"; // Replace with your Paystack public key

const WheelContainer = styled("div")({
  position: "relative",
  width: "256px",
  height: "256px",
  margin: "0 auto 16px",
  border: "4px solid #1a1a1a",
  borderRadius: "50%",
  overflow: "hidden",
});

const WheelSegment = styled("div")(({ rotate, backgroundColor }) => ({
  position: "absolute",
  top: 0,
  left: "50%", // Anchor the segment at the center of the wheel
  width: "50%",
  height: "100%",
  backgroundColor: backgroundColor || "#f39c12", // Default fallback color
  transformOrigin: "0 50%", // Set the rotation anchor to the left-middle point
  transform: `rotate(${rotate}deg)`, // Rotate by the starting angle of the segment
  clipPath: `polygon(100% 0%, 0% 50%, 100% 100%)`, // Create a pie-shaped sector
  zIndex: 1,
}));

const WheelPointer = styled("div")({
  position: "absolute",
  top: 0,
  left: "50%",
  width: 0,
  height: 0,
  borderLeft: "15px solid transparent",
  borderRight: "15px solid transparent",
  borderTop: "30px solid #ff0000",
  transform: "translateX(-50%)",
  zIndex: 10,
});

export default function SpinWheel() {
  const [prizes, setPrizes] = useState([]);
  const [isSpinning, setIsSpinning] = useState(false);
  const [result, setResult] = useState("");
  const [hoveredPrize, setHoveredPrize] = useState(null); // State to track the hovered prize
  const [wheelRotation, setWheelRotation] = useState(0);
  const [email, setEmail] = useState("");
  const [hasPaid, setHasPaid] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPrizes = async () => {
      try {
        const response = await fetch(
          "https://api-play.skicomltd.com/api/prizes"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (Array.isArray(data) && data.length > 0) {
          setPrizes(data);
        } else {
          throw new Error("Invalid data received from API");
        }
      } catch (e) {
        console.error("Error fetching prizes:", e);
        setError("Failed to fetch prizes. Using default prizes.");
      }
    };

    fetchPrizes();

    // Load Paystack script
    if (typeof window !== "undefined" && !window.PaystackPop) {
      const script = document.createElement("script");
      script.src = "https://js.paystack.co/v1/inline.js";
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  const spinWheel = () => {
    if (!hasPaid) {
      setError("Please pay to spin the wheel.");
      return;
    }

    if (isSpinning) return;

    setIsSpinning(true);
    setResult("");
    setHasPaid(false); // Reset payment after each spin

    // Send a POST request to determine the winning prize
    fetch("https://api-play.skicomltd.com/api/spin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const { prizeIndex, message } = data;
        const segmentAngle = 360 / prizes.length; // Calculate the angle per segment
        const rotationAngle = 360 * 5 + segmentAngle * prizeIndex;

        setWheelRotation(rotationAngle);

        setTimeout(() => {
          setResult(message || "");
          setIsSpinning(false); // Re-enable button after spin
        }, SPIN_DURATION);
      })
      .catch((error) => {
        console.error("Error during spin:", error);
        setError("An error occurred while spinning the wheel.");
        setIsSpinning(false); // Re-enable button if an error occurs
      });
  };

  const handlePayment = () => {
    if (typeof window === "undefined" || !window.PaystackPop) {
      setError(
        "Paystack script not loaded. Please check your internet connection and reload the page."
      );
      return;
    }

    const handler = window.PaystackPop.setup({
      key: PAYSTACK_PUBLIC_KEY,
      email: email,
      amount: SPIN_PRICE * 100,
      onClose: () => {
        console.log("Payment closed");
      },
      callback: (response) => {
        console.log(response);
        setHasPaid(true); // Allow spinning after successful payment
        setError(null); // Clear any previous errors
      },
    });
    handler.openIframe();
  };

  const segmentAngle = 360 / prizes.length; // Calculate segment angle dynamically

  return (
    <Card sx={{ maxWidth: 400, margin: "auto" }}>
      <CardHeader
        title="Spin the Wheel"
        subheader="Try your luck and win prizes!"
      />
      <CardContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        )}
        <WheelContainer>
          <motion.div
            style={{ width: "100%", height: "100%" }}
            animate={{ rotate: wheelRotation }}
            transition={{ duration: SPIN_DURATION / 1000, ease: "easeOut" }}
          >
            {prizes.map((prize, index) => (
              <WheelSegment
                key={index}
                rotate={segmentAngle * index}
                backgroundColor={prize.style?.backgroundColor}
                onMouseEnter={() => setHoveredPrize(prize.option)} // Set the hovered prize
                onMouseLeave={() => setHoveredPrize(null)} // Clear the hovered prize
              />
            ))}
          </motion.div>
          <WheelPointer />
        </WheelContainer>

        {/* Hovered prize display */}
        {hoveredPrize && (
          <Box
            mt={2}
            p={2}
            sx={{ backgroundColor: "#f0f0f0", borderRadius: "8px" }}
          >
            <Typography variant="h6" align="center">
              {hoveredPrize}
            </Typography>
          </Box>
        )}

        <TextField
          fullWidth
          type="email"
          label="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 2 }}
        />
        {!hasPaid ? (
          <Button variant="contained" fullWidth onClick={handlePayment}>
            Pay ₦{SPIN_PRICE} to Spin
          </Button>
        ) : (
          <Button
            variant="contained"
            fullWidth
            onClick={spinWheel}
            disabled={isSpinning} // Disable button while spinning
          >
            {isSpinning ? "Spinning..." : "Spin"}
          </Button>
        )}
        {result && (
          <Box mt={2}>
            <Typography variant="h6" align="center" color="success.main">
              {result}
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
}
